import { BaseForm, FormBuilder, Modal } from '@guzguz/guzguz-react-front-lib';
import { useCallback } from 'react';
import useFormSubmitUtils from '../../../../utils/useFormSubmitUtils';

const FORM_ID = 'invite-team-member-form';

const FORM_FIELDS = [
  {
    field: {
      componentType: 'InputText',
      type: 'email',
      name: 'emailAddress',
      placeholder: 'toto@gmail.com',
      addonContent: 'Email',
      ariaLabel: 'Email',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
];

function InviteTeamMemberModal({
  teamId,
  showInviteTeamMemberModal,
  setShowInviteTeamMemberModal,
}) {
  const resourceUrlBuilder = useCallback(
    (resourceId) => `/teams/${teamId}/invitations`,
    [teamId],
  );

  const {
    submit,
  } = useFormSubmitUtils({
    resourceId: undefined,
    resourceUrlBuilder,
  });

  return (
    <Modal
      title="Ajouter un membre à la Team"
      show={showInviteTeamMemberModal}
      size="xl"
      close={() => setShowInviteTeamMemberModal(false)}
      headerCloseButton={false}
      hideOk
    >
      <BaseForm
        buttonLabel={(
          <>
            Envoyer l&apos;invit&apos;!
          </>
        )}
        defaultValues={{}}
        loading={false}
        formId={FORM_ID}
        onSubmit={submit}
      >
        <FormBuilder fields={FORM_FIELDS} />
      </BaseForm>
    </Modal>
  );
}

export default InviteTeamMemberModal;
