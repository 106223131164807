import Home from '../../pages/public/home/Home';
import TeamsPage from '../../pages/secured/teams-page/TeamsPage';
import CreateTeamPage from '../../pages/secured/create-team-page/CreateTeamPage';
import TeamDetailsPage from '../../pages/secured/team-details-page/TeamDetailsPage';

const Routes = [
  {
    path: '/',
    element: (<Home />),
  },
  {
    path: '/teams',
    element: (<TeamsPage />),
  },
  {
    path: '/teams/creer',
    element: (<CreateTeamPage />),
  },
  {
    path: '/teams/:id',
    element: (<TeamDetailsPage />),
  },
];

export default Routes;
