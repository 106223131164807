import { HashRouter } from 'react-router-dom';
import {
  GuzGuzApp,
  LayoutContainer,
  LayoutElement,
  LayoutElementGroup,
} from '@guzguz/guzguz-react-front-lib';
import { faScissors } from '@fortawesome/free-solid-svg-icons';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import NavbarItems from './navigation/NavbarItems';
import Routes from './navigation/Routes';

const firebaseConfig = {
  apiKey: 'AIzaSyCf1qbSMESIF9c9kgLrwfcTxSfWObFGLBc',
  authDomain: 'split-d18a7.firebaseapp.com',
  projectId: 'split-d18a7',
  storageBucket: 'split-d18a7.appspot.com',
  messagingSenderId: '337635005111',
  appId: '1:337635005111:web:f0e0155b012c3f46747332',
  measurementId: 'G-E8SF2CJ03J',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// TODO const analytics =
getAnalytics(app);

function App() {
  return (
    <GuzGuzApp
      navbar={{
        websiteName: 'SubSplitter',
        items: NavbarItems,
        logoIcon: faScissors,
      }}
      auth0={{
        domain: 'guzguz.eu.auth0.com', // TODO
        clientId: 'hR8Zzk1W5qGfoQ08zoGkLDRBtaFDyoxl',
        redirectUri: window.location.origin,
        audience: 'https://menu-api.example.com', // TODO
        scope: 'read:current_user update:current_user_metadata openid email profile',
        useRefreshTokens: true,
      }}
      router={{
        routerComponent: HashRouter,
        routes: Routes,
      }}
      appProperties={{
        baseApiUrl: process.env.REACT_APP_API_BASE_URL,
      }}
    >
      <LayoutContainer>
        <LayoutElementGroup row={{ className: 'py-2' }}>
          <LayoutElement
            centered
            textCentered
          >
            &copy; 2023 - GuzGuz/Split - Made with ❤️ in Provence, France
          </LayoutElement>
        </LayoutElementGroup>
      </LayoutContainer>
    </GuzGuzApp>
  );
}

export default App;
