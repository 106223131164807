import {
  BaseTable,
  LayoutElement,
  LayoutElementGroup,
  Panel,
  PanelTitle,
  useFetchFromApi,
} from '@guzguz/guzguz-react-front-lib';
import axios from 'axios';

function TeamTransactionsPanel({ teamId }) {
  const {
    data: teamTransactions,
    reload,
  } = useFetchFromApi(
    null,
    `/teams/${teamId}/transactions`,
    axios.get,
    true,
    (data) => data,
    true,
  );

  return (
    <Panel directBody>
      <LayoutElementGroup>
        <LayoutElement col={{ md: 6 }}>
          <PanelTitle>
            Les transactions de la Team
          </PanelTitle>
        </LayoutElement>
      </LayoutElementGroup>

      <BaseTable
        headers={['Description', 'Montant', 'Date']}
        lines={teamTransactions
          ?.map(({ description, amount, date }) => [description, amount, date])}
        rowLink={() => {
        }}
      />
    </Panel>
  );
}

export default TeamTransactionsPanel;
