import {
  BasePage,
  DefaultPageLayout,
  LayoutContainer, LayoutElement,
  LayoutElementGroup, Panel, PanelTitle,
  useAuth,
} from '@guzguz/guzguz-react-front-lib';

function Home() {
  const {
    isAuthenticated,
    isLoading,
  } = useAuth(false);

  if (isAuthenticated) {
    return (
      <p>Logged In</p>
    );
  }

  return (
    <BasePage
      loading={isLoading}
      title="Bienvenue sur Sub Splitter !"
    >
      <LayoutContainer>
        <DefaultPageLayout>
          <LayoutElementGroup>
            <LayoutElement
              col={{
                md: 10,
                className: 'my-5',
              }}
              centered
            >
              <Panel directBody>
                <PanelTitle>
                  Prêt à Splitter vos abos ?
                </PanelTitle>

                Connectez vous ou inscrivez pour vous lancer! :)
              </Panel>
            </LayoutElement>
          </LayoutElementGroup>
        </DefaultPageLayout>
      </LayoutContainer>
    </BasePage>
  );
}

export default Home;
