import {
  BaseTable,
  LayoutElement,
  LayoutElementGroup,
  Panel,
  PanelTitle,
  useFetchFromApi,
} from '@guzguz/guzguz-react-front-lib';
import axios from 'axios';

function TeamMemberTransactionsPanel({ teamId }) {
  const {
    data: teamMemberTransactions,
    reload,
  } = useFetchFromApi(
    null,
    `/teams/${teamId}/member/transactions`,
    axios.get,
    true,
    (data) => data,
    true,
  );

  return (
    <Panel directBody>
      <LayoutElementGroup>
        <LayoutElement col={{ md: 6 }}>
          <PanelTitle>
            Mes transactions personnelles
          </PanelTitle>
        </LayoutElement>
      </LayoutElementGroup>

      <BaseTable
        headers={['Description', 'Montant', 'Date']}
        lines={teamMemberTransactions
          ?.map(({
            description,
            amount: { currency, amount },
            date,
          }) => ([description, amount, date]))}
        rowLink={() => {
        }}
      />
    </Panel>
  );
}

export default TeamMemberTransactionsPanel;
