import {
  BaseForm,
  FormBuilder,
  LayoutElement,
  LayoutElementGroup,
  Panel,
  PanelTitle,
  useFetchFromApi,
} from '@guzguz/guzguz-react-front-lib';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';
import axios from 'axios';
import useFormSubmitUtils from '../../../../utils/useFormSubmitUtils';

const FORM_ID = 'send-team-message-form';

const FORM_FIELDS = [
  {
    field: {
      componentType: 'InputText',
      name: 'subject',
      placeholder: 'Un sujet parmis tant d\'autres',
      addonContent: 'Sujet',
      ariaLabel: 'Sujet',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
  {
    field: {
      componentType: 'TextArea',
      name: 'message',
      placeholder: 'Un super message pour la team!',
      addonContent: 'Message',
      ariaLabel: 'Message',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
];

function TeamMessagesPanel({ teamId }) {
  const dispatch = useDispatch();

  const {
    data: messages,
    reload,
  } = useFetchFromApi(
    null,
    `/teams/${teamId}/messages`,
    axios.get,
    true,
    (data) => data,
    true,
  );

  const resourceUrlBuilder = useCallback(
    (resourceId) => `/teams/${teamId}/messages`,
    [teamId],
  );

  const {
    submit,
  } = useFormSubmitUtils({
    resourceId: undefined,
    resourceUrlBuilder,
  });

  const getMessagePanelBg = (index) => {
    if (index % 2 === 0) {
      return 'light';
    }
    return 'lighter';
  };

  return (
    <Panel
      title="Les messages de la Team"
      directBody
    >
      <LayoutElement
        className="overflow-scroll"
        col={{
          style: { maxHeight: '350px' },
        }}
      >
        {messages?.map(({
          id,
          subject,
          content,
          senderUserName,
          senderUserIsMe,
        }, index) => (
          <LayoutElementGroup key={id}>
            <LayoutElement
              col={{
                md: {
                  span: 8,
                  offset: senderUserIsMe ? 4 : 0,
                },
              }}
            >
              <Panel
                bg={getMessagePanelBg(index)}
                borderVariant={senderUserIsMe ? 'success' : 'transparent'}
                directBody
              >
                <b><u>{senderUserName}</u></b>
                <br />
                <b>{subject}</b>
                <br />
                {content}
              </Panel>
            </LayoutElement>
          </LayoutElementGroup>
        ))}
      </LayoutElement>

      <Panel directBody>
        <PanelTitle big={6}>
          Envoyer un message
        </PanelTitle>
        <BaseForm
          buttonLabel={(
            <>
              Envoyer
            </>
          )}
          defaultValues={{}}
          formId={FORM_ID}
          onSubmit={(values) => submit(values)
            .then(() => {
              dispatch(reset(FORM_ID));
              reload();
            })}
        >
          <FormBuilder fields={FORM_FIELDS} />
        </BaseForm>
      </Panel>
    </Panel>
  );
}

export default TeamMessagesPanel;
