const NavbarItems = [
  {
    name: 'Accueil',
    href: '/',
    noAuth: true,
  },
  {
    name: 'Mes Teams',
    href: '/teams',
    userType: [null],
  },
];

export default NavbarItems;
