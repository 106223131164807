import {
  BaseForm,
  BasePage,
  DefaultPageLayout,
  FormBuilder,
  LayoutContainer,
  LayoutElement,
  LayoutElementGroup,
  Panel,
} from '@guzguz/guzguz-react-front-lib';
import useCrudPageUtils from '../../../utils/useCrudPageUtils';

const FORM_ID = 'create-team-form';

const FORM_FIELDS = [
  {
    field: {
      componentType: 'InputText',
      name: 'name',
      placeholder: 'xX__[[MA---SUPER__TEAM_DU_SPLIT🚀 🚀 ]]__Xx',
      addonContent: 'Nom de la Team',
      ariaLabel: 'Nom de la Team',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
  {
    field: {
      componentType: 'TextArea',
      name: 'description',
      placeholder: 'Une ptite Team pour enfin vraiment split les abos!\nFinie la galère :)',
      addonContent: 'Description',
      ariaLabel: 'Description de la Team',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
];

function CreateTeamPage() {
  const {
    submitAndNavigate,
    initialValues,
    loading,
  } = useCrudPageUtils({
    resourceUrlBuilder: (resourceId) => '/teams',
  });

  return (
    <BasePage
      title="Créer une Team"
      loading={loading}
    >
      <DefaultPageLayout>
        <LayoutContainer>
          <LayoutElementGroup>
            <LayoutContainer>
              <LayoutElementGroup>
                <LayoutElement>
                  <Panel directBody>
                    <BaseForm
                      buttonLabel={(
                        <>
                          Créer une Team !
                        </>
                      )}
                      defaultValues={initialValues}
                      formId={FORM_ID}
                      onSubmit={submitAndNavigate}
                    >
                      <FormBuilder fields={FORM_FIELDS} />
                    </BaseForm>
                  </Panel>
                </LayoutElement>
              </LayoutElementGroup>
            </LayoutContainer>
          </LayoutElementGroup>
        </LayoutContainer>
      </DefaultPageLayout>
    </BasePage>
  );
}

export default CreateTeamPage;
