import {
  BaseTable,
  LayoutElement,
  LayoutElementGroup,
  Panel,
  PanelTitle,
  useFetchFromApi,
  useAuth,
} from '@guzguz/guzguz-react-front-lib';
import { Button } from 'react-bootstrap';
import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import AddTeamSubscriptionModal from './AddTeamSubscriptionModal';

function TeamSubscriptionsPanel({ teamId }) {
  const [showAddTeamSubscriptionModal, setShowAddTeamSubscriptionModal] = useState(false);
  const [teamSubscriptionIdToEdit, setTeamSubscriptionIdToEdit] = useState(null);

  const subscriptions = useFetchFromApi(
    null,
    `/teams/${teamId}/subscriptions`,
  );

  const rowLink = useCallback((index) => {
    if (subscriptions[index]?.id) {
      // todo return `./${subscriptions[index].id}`;
      setTeamSubscriptionIdToEdit(subscriptions[index]?.id);
      setShowAddTeamSubscriptionModal(true);
    }
    return null;
  }, [subscriptions]);

  const headers = [
    'Nom',
    'Description',
    'Prix',
    'Payé par',
  ];

  const mappedLines = useMemo(
    () => subscriptions?.map(({
      name,
      description,
      numberOfSeats,
      billing: {
        amount,
        currency,
        period,
      },
    }) => ([
      name,
      description,
      `${amount} ${currency} / ${period === 'Yearly' ? 'an' : 'mois'}`,
      '',
    ])),
    [subscriptions],
  );

  return (
    <Panel directBody>
      <AddTeamSubscriptionModal
        key={teamSubscriptionIdToEdit}
        teamSubscriptionId={teamSubscriptionIdToEdit}
        setTeamSubscriptionId={setTeamSubscriptionIdToEdit}
        teamId={teamId}
        showAddTeamSubscriptionModal={showAddTeamSubscriptionModal}
        setShowAddTeamSubscriptionModal={setShowAddTeamSubscriptionModal}
      />
      <LayoutElementGroup>
        <LayoutElement col={{ md: 6 }}>
          <PanelTitle>
            Les abos de la Team
          </PanelTitle>
        </LayoutElement>
        <LayoutElement
          col={{ md: 6 }}
          textCentered="right"
        >
          <Button
            onClick={() => {
              setTeamSubscriptionIdToEdit(undefined);
              setShowAddTeamSubscriptionModal(true);
            }}
            variant="outline-primary"
          >
            Ajouter un abo
          </Button>
        </LayoutElement>
      </LayoutElementGroup>
      <LayoutElementGroup>
        <LayoutElement>
          <BaseTable
            headers={headers}
            lines={mappedLines}
            rowLink={rowLink}
          />
        </LayoutElement>
      </LayoutElementGroup>
    </Panel>
  );
}

export default TeamSubscriptionsPanel;
