import { useAuth, useFetchFromApi } from '@guzguz/guzguz-react-front-lib';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useFormSubmitUtils from './useFormSubmitUtils';

const formatDate = (date) => {
  if (!date) {
    return '';
  }

  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString()} - ${dateObject.toLocaleTimeString()}`;
};

const useCrudPageUtils = (
  {
    initialState = null,
    resourceId,
    resourceUrlBuilder,
  },
) => {
  const navigate = useNavigate();
  const { actions: { runAuthenticated } } = useAuth();

  const resourceUrl = resourceUrlBuilder(resourceId);

  const initialValues = useFetchFromApi(
    initialState,
    resourceId ? resourceUrl : undefined,
  );

  const [finalInitialValues, setFinalInitalValues] = useState(initialState);
  useEffect(() => {
    setFinalInitalValues(initialValues ? {
      ...initialValues,
      createdDate: formatDate(initialValues.createdDate),
      originalCreatedDate: initialValues.createdDate,
      lastModifiedDate: formatDate(initialValues.lastModifiedDate),
      originalLastModifiedDate: initialValues.lastModifiedDate,
    } : initialValues);
  }, [initialValues]);

  const { submit, submitAndNavigate } = useFormSubmitUtils(
    { initialState, resourceId, resourceUrlBuilder },
  );

  const deleteResourceAndNavigate = useCallback(
    (navigateUrl = './') => resourceUrl && resourceId
      && runAuthenticated(
        axios.delete,
        resourceUrl,
      )
        .then(() => navigateUrl && navigate(navigateUrl)),
    [resourceUrl, resourceId, navigate, runAuthenticated],
  );

  return {
    initialValues: finalInitialValues,
    deleteResourceAndNavigate,
    submit,
    submitAndNavigate,
    loading: resourceId && !initialValues,
  };
};

export default useCrudPageUtils;
