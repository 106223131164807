import {
  BasePage,
  DefaultPageLayout,
  LayoutContainer,
  LayoutElement,
  LayoutElementGroup,
} from '@guzguz/guzguz-react-front-lib';
import { useParams } from 'react-router-dom';
import useCrudPageUtils from '../../../utils/useCrudPageUtils';
import TeamSubscriptionsPanel from './subscriptions/TeamSubscriptionsPanel';
import TeamMessagesPanel from './messages/TeamMessagesPanel';
import TeamMembersPanel from './members/TeamMembersPanel';
import TeamBadges from '../components/team-badges/TeamBadges';
import TeamInvitationsPanel from './invitations/TeamInvitationsPanel';
import TeamMemberTransactionsPanel from './member-transactions/TeamMemberTransactionsPanel';
import TeamTransactionsPanel from './transactions/TeamTransactionsPanel';
import TeamEventsPanel from './events/TeamEventsPanel';

function TeamDetailsPage() {
  const { id } = useParams();

  const isTeamEventsPanelEnabled = false;
  const isTeamMessagesPanelEnabled = false;

  const {
    initialValues: team,
    loading,
  } = useCrudPageUtils({
    resourceId: id,
    resourceUrlBuilder: (resourceId) => `/teams${resourceId ? `/${resourceId}` : ''}`,
  });

  return (
    <BasePage
      title={`Team ${team?.name ?? ''}`}
      subtitle={team?.description ?? ''}
      headerCardContent={(
        <LayoutElement textCentered="right">
          <TeamBadges
            admin={team?.admin}
            creator={team?.creator}
            teamMembersCount={team?.teamMembersCount}
          />
        </LayoutElement>
      )}
      loading={loading}
    >
      <DefaultPageLayout>
        <LayoutContainer>

          <LayoutElementGroup>
            <LayoutContainer>
              <LayoutElementGroup>
                <LayoutElement>
                  <TeamSubscriptionsPanel teamId={id} />
                </LayoutElement>
              </LayoutElementGroup>

              {(isTeamEventsPanelEnabled || isTeamMessagesPanelEnabled) && (
                <LayoutElementGroup>
                  {isTeamEventsPanelEnabled && (
                    <LayoutElement col={{ md: 6 }}>
                      <TeamEventsPanel teamId={id} />
                    </LayoutElement>
                  )}

                  {isTeamMessagesPanelEnabled && (
                    <LayoutElement col={{ md: 6 }}>
                      <TeamMessagesPanel teamId={id} />
                    </LayoutElement>
                  )}
                </LayoutElementGroup>
              )}

              <LayoutElementGroup>
                <LayoutElement col={{ md: 6 }}>
                  <TeamMembersPanel teamId={id} />
                </LayoutElement>

                <LayoutElement col={{ md: 6 }}>
                  <TeamInvitationsPanel teamId={id} />
                </LayoutElement>
              </LayoutElementGroup>

              <LayoutElementGroup>
                <LayoutElement col={{ md: 6 }}>
                  <TeamTransactionsPanel teamId={id} />
                </LayoutElement>

                <LayoutElement col={{ md: 6 }}>
                  <TeamMemberTransactionsPanel teamId={id} />
                </LayoutElement>
              </LayoutElementGroup>
            </LayoutContainer>
          </LayoutElementGroup>
        </LayoutContainer>
      </DefaultPageLayout>
    </BasePage>
  );
}

export default TeamDetailsPage;
