import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@guzguz/guzguz-react-front-lib';

const formatDate = (date) => {
  if (!date) {
    return '';
  }

  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString()} - ${dateObject.toLocaleTimeString()}`;
};

const useFormSubmitUtils = (
  {
    resourceId,
    resourceUrlBuilder,
    setFinalInitalValues = (finalInitialValues) => {},
  },
) => {
  const navigate = useNavigate();
  const { actions: { runAuthenticated } } = useAuth();

  const resourceUrl = resourceUrlBuilder(resourceId);

  const submit = useCallback(
    ({
      id,
      createdDate,
      originalCreatedDate,
      lastModifiedDate,
      originalLastModifiedDate,
      ...values
    }) => runAuthenticated(
      resourceId ? axios.put : axios.post,
      resourceUrl,
      {
        ...values,
        id,
        createdDate: originalCreatedDate,
        lastModifiedDate: originalLastModifiedDate,
      },
    ),
    [resourceId, navigate, runAuthenticated, setFinalInitalValues],
  );

  const submitAndNavigate = useCallback(
    ({
      id,
      createdDate,
      originalCreatedDate,
      lastModifiedDate,
      originalLastModifiedDate,
      ...values
    }) => submit({
      id,
      createdDate,
      originalCreatedDate,
      lastModifiedDate,
      originalLastModifiedDate,
      ...values,
    })
      .then(({ data }) => {
        if (resourceId) {
          setFinalInitalValues(data ? {
            ...data,
            createdDate: formatDate(data.createdDate),
            originalCreatedDate: data.createdDate,
            lastModifiedDate: formatDate(data.lastModifiedDate),
            originalLastModifiedDate: data.lastModifiedDate,
          } : data);
        } else {
          navigate(`./../${data.id || data.createdItemId}`);
        }
      }),
    [resourceId, navigate, runAuthenticated, setFinalInitalValues],
  );

  return {
    submit,
    submitAndNavigate,
  };
};

export default useFormSubmitUtils;
