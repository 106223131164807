import {
  BaseForm,
  FormBuilder,
  InputText,
  LayoutElement,
  Modal,
} from '@guzguz/guzguz-react-front-lib';
import { useCallback, useState } from 'react';
import useFormSubmitUtils from '../../../../utils/useFormSubmitUtils';
import useCrudPageUtils from '../../../../utils/useCrudPageUtils';

const FORM_ID = 'add-team-subscription-form';

const FORM_FIELDS = [
  {
    field: {
      componentType: 'TextArea',
      name: 'description',
      placeholder: 'Le ptit abo pour regarder des séries et des films en streaming avec toute la Faf ;)\'',
      addonContent: 'Description',
      ariaLabel: 'Description',
      required: true,
      readOnly: false,
    },
    layoutElement: true,
  },
  {
    field: {
      componentType: 'InputText',
      type: 'number',
      name: 'billing.amount',
      placeholder: 'Prix',
      addonContent: 'Prix',
      ariaLabel: 'Prix',
      required: true,
      readOnly: false,
    },
    col: { md: 3 },
    layoutElement: true,
  },
  {
    field: {
      componentType: 'Select',
      name: 'billing.period',
      placeholder: 'Fréquence',
      addonContent: 'Fréquence',
      ariaLabel: 'Fréquence',
      options: [
        {
          id: 'Monthly',
          label: 'Mensuel',
        },
        {
          id: 'Yearly',
          label: 'Annuel',
        },
      ],
      optionIdKey: 'id',
      optionLabelKey: 'label',
      selectionLimit: 1,
      required: true,
      readOnly: false,
    },
    col: { md: 3 },
    layoutElement: false,
  },
  {
    field: {
      componentType: 'InputText',
      type: 'number',
      name: 'numberOfSeats',
      placeholder: 'Nombre de places',
      addonContent: 'Nombre de places',
      ariaLabel: 'Nombre de places',
      required: false,
      readOnly: false,
    },
    col: { md: 3 },
    layoutElement: true,
  },
];

function AddTeamSubscriptionModal({
  teamSubscriptionId,
  setTeamSubscriptionId,
  teamId,
  showAddTeamSubscriptionModal,
  setShowAddTeamSubscriptionModal,
}) {
  const resourceUrlBuilder = useCallback(
    (resourceId) => `/teams/${teamId}/subscriptions${resourceId ? `/${resourceId}` : ''}`,
    [teamId],
  );

  const {
    initialValues,
    loading,
    submit,
  } = useCrudPageUtils({
    resourceId: teamSubscriptionId,
    resourceUrlBuilder,
  });

  const close = () => {
    setTeamSubscriptionId(null);
    setShowAddTeamSubscriptionModal(false);
  };

  return (
    <Modal
      title="Ajouter un abo"
      show={showAddTeamSubscriptionModal}
      size="xl"
      close={close}
      headerCloseButton={false}
      hideOk
    >
      <BaseForm
        loading={loading}
        buttonLabel={(
          <>
            Ajouter l&apos;Abo!
          </>
        )}
        defaultValues={initialValues}
        formId={FORM_ID}
        onSubmit={submit}
      >
        <LayoutElement>
          <InputText
            addonContent="Nom de l'Abo"
            ariaLabel="Nom de l'Abo"
            name="name"
            placeholder="Netflix"
            required
          />
        </LayoutElement>
        <FormBuilder fields={FORM_FIELDS} />
      </BaseForm>
    </Modal>
  );
}

export default AddTeamSubscriptionModal;
