import {
  BaseTable,
  LayoutElement,
  LayoutElementGroup,
  Panel,
  PanelTitle, useFetchFromApi,
} from '@guzguz/guzguz-react-front-lib';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import InviteTeamMemberModal from '../members/InviteTeamMemberModal';

function TeamInvitationsPanel({ teamId }) {
  const {
    data: teamInvitations,
    reload,
  } = useFetchFromApi(
    null,
    `/teams/${teamId}/invitations`,
    axios.get,
    true,
    (data) => data,
    true,
  );

  const [showInviteTeamMemberModal, setShowInviteTeamMemberModal] = useState(false);

  return (
    <>
      <InviteTeamMemberModal
        teamId={teamId}
        showInviteTeamMemberModal={showInviteTeamMemberModal}
        setShowInviteTeamMemberModal={setShowInviteTeamMemberModal}
      />
      <Panel directBody>
        <LayoutElementGroup>
          <LayoutElement col={{ md: 6 }}>
            <PanelTitle>
              Invitations en attente
            </PanelTitle>
          </LayoutElement>
          <LayoutElement
            col={{ md: 6 }}
            textCentered="right"
          >
            <Button
              onClick={() => setShowInviteTeamMemberModal(true)}
              variant="outline-primary"
            >
              Inviter un membre
            </Button>
          </LayoutElement>
        </LayoutElementGroup>

        <BaseTable
          headers={['E-Mail']}
          lines={teamInvitations?.map(({ emailAddress }) => [emailAddress])}
          rowLink={() => {
          }}
        />
      </Panel>
    </>
  );
}

export default TeamInvitationsPanel;
