import { Badge } from 'react-bootstrap';

function ConditionalBadge({
  condition,
  children,
  ...props
}) {
  if (!condition) {
    return null;
  }
  return (
    <>
      {' '}
      <Badge {...props}>
        {children}
      </Badge>
    </>
  );
}

function TeamBadges({
  admin,
  creator,
  teamMembersCount,
}) {
  return (
    <>
      <ConditionalBadge condition={admin} bg="dark" text="white">
        Admin
      </ConditionalBadge>
      <ConditionalBadge condition={creator} bg="success">
        Créateur
      </ConditionalBadge>
      <ConditionalBadge condition={teamMembersCount !== undefined} bg="info">
        {teamMembersCount}
        {' '}
        membres
      </ConditionalBadge>
    </>
  );
}

export default TeamBadges;
