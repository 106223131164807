import { useNavigate } from 'react-router-dom';
import {
  BasePage,
  BaseTable,
  DefaultPageLayout,
  LayoutContainer,
  LayoutElement,
  LayoutElementGroup,
  useFetchFromApi,
} from '@guzguz/guzguz-react-front-lib';
import { useCallback, useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import TeamBadges from '../components/team-badges/TeamBadges';

function TeamsPage() {
  const myTeamsResponse = useFetchFromApi(
    null,
    '/teams',
  );

  const teams = useMemo(() => myTeamsResponse?.items, [myTeamsResponse]);

  const history = useNavigate();

  const rowLink = useCallback((index) => {
    if (teams[index]?.id) {
      return `./${teams[index].id}`;
    }
    return null;
  }, [teams]);

  const headers = [
    'Nom de la Team',
    'Description',
    '-',
  ];

  const mappedLines = useMemo(() => teams?.map(
    ({
      name,
      description,
      teamMembersCount,
      admin,
      creator,
    }) => ([
      (
        <>
          {name}
          <TeamBadges
            admin={admin}
            creator={creator}
            teamMembersCount={teamMembersCount}
          />
        </>
      ),
      description,
      <>-</>,
    ]),
  ), [teams]);

  return (
    <BasePage
      title="Mes Teams"
      subtitle={teams && `${teams.length} teams`}
      headerCardContent={(
        <LayoutElement
          textCentered="right"
        >
          <Button
            onClick={() => history('./creer')}
            variant="outline-primary"
          >
            Creer une Team !
          </Button>
        </LayoutElement>
      )}
      loading={!teams}
    >
      <DefaultPageLayout>
        <LayoutContainer>
          <LayoutElementGroup>
            <LayoutContainer>
              <BaseTable
                headers={headers}
                lines={mappedLines}
                rowLink={rowLink}
              />
            </LayoutContainer>
          </LayoutElementGroup>
        </LayoutContainer>
      </DefaultPageLayout>
    </BasePage>
  );
}

export default TeamsPage;
